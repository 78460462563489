body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;

  /* CortexJS math-field customization */
  --keyboard-toolbar-background: 'white';
}

body #mathlive-suggestion-popover {
  z-index: 9999;
  display: 'none' !important;
  left: '-9999px' !important;
}

* {
  box-sizing: border-box;
  touch-action: manipulation;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'RobotoMedium';
  src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'erasdust';
  src: url('./assets/fonts/erasdust.ttf') format('truetype');
}


/* Inter */
@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: url('./assets/fonts/inter/Inter-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('./assets/fonts/inter/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: url('./assets/fonts/inter/Inter-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: url('./assets/fonts/inter/Inter-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url('./assets/fonts/inter/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('./assets/fonts/inter/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('./assets/fonts/inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('./assets/fonts/inter/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url('./assets/fonts/inter/Inter-Thin.ttf') format('truetype');
}